import React from "react"
import type { ButtonProps } from "@chakra-ui/react"
import { Button } from "@chakra-ui/react"

type IntercomWindow = {
  Intercom?: () => void
  intercomSettings?: {
    custom_launcher_selector?: string
  }
} & Window

function isIntercomAvailable(): boolean {
  return Boolean(
    typeof window !== "undefined" &&
      !!(window as IntercomWindow)?.Intercom &&
      typeof (window as IntercomWindow).Intercom === "function",
  )
}

const IntercomLauncher: React.FC<ButtonProps> = (props) => {
  // Intercom is not available
  if (!isIntercomAvailable()) return null

  const intercomWindow = window as IntercomWindow

  // Get the custom launcher selector from intercomSettings
  const customLauncherSelector =
    intercomWindow.intercomSettings?.custom_launcher_selector ??
    "#open_web_chat"

  // Extract the ID from the selector (remove the # if present)
  const launcherId = customLauncherSelector.startsWith("#")
    ? customLauncherSelector.substring(1)
    : customLauncherSelector

  return <Button id={launcherId} {...props} />
}

export default IntercomLauncher
