import type { FC } from "react"
import React from "react"
import { Stack, Text, Button, HStack } from "@chakra-ui/react"

import Link from "common/components/Link"
import MainLayout from "ui/layouts/main/MainLayout"
import MainLayoutHead from "ui/layouts/main/MainLayoutHead"
import MainLayoutHeader from "ui/layouts/main/MainLayoutHeader"
import MainLayoutContainer from "ui/layouts/main/MainLayoutContainer"
import MainLayoutFooter from "ui/layouts/main/MainLayoutFooter"
import { TALLY_DEFAULT_SOCIAL_PREVIEW } from "common/constants/logo"
import { ROUTES } from "common/constants/routes"
import { useIsWhiteLabel } from "whitelabel/hooks/useIsWhiteLabel"
import IntercomLauncher from "common/components/IntercomLauncher"

const Custom404: FC = () => {
  const preview = {
    title: "Tally | Start, join, and grow DAOs",
    image: TALLY_DEFAULT_SOCIAL_PREVIEW,
    description: "Page Not Found",
  }

  const isWhiteLabel = useIsWhiteLabel()

  return (
    <MainLayout>
      <MainLayoutHead preview={preview} title="Tally" />
      <MainLayoutHeader isWhiteLabel={isWhiteLabel} />
      <MainLayoutContainer
        alignItems="center"
        display="flex"
        justifyContent="center"
        maxW="4xl"
      >
        <Stack align="center" maxW="2xl" mb={12} mt={6} spacing={2}>
          <Text as="h3" fontSize="4xl" fontWeight="bold" pb={6} textStyle="h3">
            Page Not Found
          </Text>
          <Text align="center" fontWeight="bold" width="100%">
            Oops! Why are you here?
          </Text>
          <Text align="center" width="100%">
            {`We couldn\'t find the page you are looking for.`}
          </Text>
          <HStack pt={6}>
            <IntercomLauncher>Chat with support</IntercomLauncher>
            <Link className="no-underline" href={ROUTES.home()}>
              <Button data-qa="404page-backtohome-btn" variant="primary">
                Back to home
              </Button>
            </Link>
          </HStack>
        </Stack>
      </MainLayoutContainer>
      <MainLayoutFooter isWhiteLabel={isWhiteLabel} mt={0} />
    </MainLayout>
  )
}

export default Custom404
